















































import { Component, Mixins, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import WeekCodeMethods from '@/components/atoms/WeekCodeMethods.vue'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import { GetStudentsResponseType } from '@/models/api/students'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import GlinkBranchControlable from '@/mixins/teacher/GlinkBranchControlable'
import LoadStudentsApi from '@/mixins/teacher/LoadStudentsApi'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    TabBase,
    InputWithLabel,
    RadioGroup,
    SelectBase,
    TableBase,
  },
})
export default class StudentEdit extends Mixins(
  WeekCodeMethods,
  QueryMethods,
  LoadEnableSubjectsApi,
  GlinkBranchControlable,
  LoadStudentsApi
) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private userIds: number[] = []
  private searchQuery = ''
  private isProcessing = false

  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: '生徒', href: '/teacher/setting/student' },
    { text: '選択生徒設定変更', active: true },
  ]

  private tabBaseDatas = [
    { id: 1, name: '学校' },
    { id: 2, name: 'グループ' },
    { id: 3, name: '終了条件' },
  ]
  private activeTab = 1

  private schoolOptionDatas: { text: string; value: number }[] = []
  private schoolSelectedData: number | null = null

  private attributeOptionDatas: { text: string; value: number }[] = []
  private attributeSelectedData: number | null = null

  private studyTypeRadioItems = [
    { label: '演習', value: 1 },
    { label: '宿題', value: 2 },
  ]
  private studyTypeValue = 1

  private subjectOptionDatas: { text: string; value: string }[] = []

  private subjectSelectedValue: string | null = null

  private exitConditionOptionDatas = [
    { text: '問題数', value: '問題数' },
    { text: '時間', value: '時間' },
    { text: '理解度', value: '理解度' },
  ]
  private exitConditionValue: string | null = null

  private exitConditionThreshold = ''

  private tableBaseItems: {
    name: string[]
    school: string
    grade: string
    attribute: string
    gid: string
  }[] = []

  private tableBaseFields = [
    { key: 'name', label: '氏名・ID' },
    { key: 'school', label: '学校' },
    { key: 'grade', label: '学年' },
    { key: 'attribute', label: 'グループ' },
    { key: 'gid', label: 'ステータス' },
  ]

  private tableMultipleLines = ['name']

  /**
   * ボタンの色と操作制御
   */
  private get colortype(): string {
    let permitted = false
    switch (this.activeTab) {
      // 学校
      case 1:
        permitted = !!this.schoolSelectedData
        break
      // 属性
      case 2:
        permitted = !!this.attributeSelectedData
        break
      // 終了条件
      case 3:
        permitted =
          !!this.subjectSelectedValue &&
          !!this.exitConditionValue &&
          !!this.exitConditionThreshold &&
          !!this.exitConditionThreshold.match(/^[0-9]+$/) &&
          this.exitConditionThreshold.length < 4 &&
          (this.exitConditionValue !== '理解度' || parseInt(this.exitConditionThreshold) <= 100)
        break
    }
    return permitted ? 'blue' : 'pointer-events-none'
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadInitialize()
    Vue.prototype.$loading.complete()
  }

  // 初回情報読み込み
  private async loadInitialize() {
    // 学研連携対象の教室の場合、学校の設定を無効化する
    await this.isGlinkBranchApi(this.branchId)
    if (this.isGlinkBranch) this.hideSchoolSetting()

    this.setParamsFromQuery()
    // ID指定がない場合は戻る
    if (this.userIds.length == 0) {
      this.jumpToListpage()
      alert('IDなし')
      return
    }
    await this.loadSelectDatas()
    await this.loadStudentDatas()
  }

  /**
   * 学校の設定を無効化する
   */
  private hideSchoolSetting() {
    this.tabBaseDatas = [
      { id: 2, name: 'グループ' },
      { id: 3, name: '終了条件' },
    ]
    this.activeTab = 2
  }

  // クエリからパラメータ取得
  private async setParamsFromQuery() {
    const params = this.getParamsObject()
    // ID
    if (params['id']) {
      this.userIds = params['id'].split(',')
    }
    // 検索条件
    if (params['search']) {
      this.searchQuery = decodeURIComponent(params['search'])
    }
  }

  // プルダウン用情報読み込み
  private async loadSelectDatas() {
    this.loadEnableSubjects()
    this.loadAttributes()
    this.loadSchools()
  }

  private async loadEnableSubjects() {
    this.subjectOptionDatas = (await this.loadGdlsSubjectsApi(this.branchId)).map((subject) => {
      return { text: subject.name, value: subject.code }
    })
  }

  // 属性情報読み込み
  private async loadAttributes() {
    // 属性プルダウンの情報設定
    Vue.prototype.$http.httpWithToken
      .get(`/user_attributes`, { params: { branchId: this.branchId } })
      .then((res: any) => {
        this.attributeOptionDatas = res.data.userAttributes.map((userAttributes: { id: number; title: string }) => {
          return {
            text: userAttributes.title,
            value: userAttributes.id,
          }
        })
        // 先頭に空き（グループ削除）の要素を追加
        this.attributeOptionDatas.unshift({ text: 'グループなし', value: 0 })
      })
  }

  // 学校情報読み込み
  private async loadSchools() {
    Vue.prototype.$http.httpWithToken.get(`/schools`, { params: { branchId: this.branchId } }).then((res: any) => {
      this.schoolOptionDatas = res.data.schools.map((school: { id: number; name: string }) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    })
  }

  // 生徒情報読み込み・検索
  private async loadStudentDatas() {
    // 固定パラメータ設定
    const params = this.getSearchParamsBase(this.branchId)
    params['userId'] = this.userIds.join(',')
    // 生徒情報を取得
    const data: GetStudentsResponseType = await this.loadStudents(params)
    this.tableBaseItems = data.students.map((student) => {
      return {
        name: [`${student.nickname}`, `${student.studentCode}`],
        school: student.schoolName,
        grade: student.gradeName,
        attribute: student.userAttributeTitle ? student.userAttributeTitle : '－',
        gid: student.isGidRegistered ? '済' : '未',
      }
    })
  }

  private submit() {
    switch (this.activeTab) {
      // 学校
      case 1:
        this.updateSchool()
        break
      // 属性
      case 2:
        this.updateAttribute()
        break
      // 終了条件
      case 3:
        this.updateEndConditions()
        break
    }
  }

  // 学校更新
  private async updateSchool() {
    if (this.isProcessing) return
    this.isProcessing = true

    const params = {
      studentIds: this.userIds,
      schoolId: this.schoolSelectedData,
      branchId: this.branchId,
    }
    Vue.prototype.$http.httpWithToken
      .patch('/students/', params)
      .then(() => {
        alert('更新しました。')
        this.jumpToListpage()
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.isProcessing = false
        this.jumpToListpage()
      })
  }

  // 属性更新
  private async updateAttribute() {
    if (this.isProcessing) return
    this.isProcessing = true

    const params = {
      studentIds: this.userIds,
      userAttributeId: this.attributeSelectedData,
      branchId: this.branchId,
    }
    Vue.prototype.$http.httpWithToken
      .patch('/students/', params)
      .then(() => {
        alert('更新しました。')
        this.jumpToListpage()
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.isProcessing = false
        this.jumpToListpage()
      })
  }

  // 終了条件更新
  private async updateEndConditions() {
    if (this.isProcessing) return
    this.isProcessing = true

    const params = {
      studentIds: this.userIds,
      restriction: this.exitConditionValue,
      subjectCode: this.subjectSelectedValue,
      threshold: this.exitConditionThreshold,
      isHomework: this.studyTypeValue == 1 ? false : true,
    }
    Vue.prototype.$http.httpWithToken
      .patch('/studentEndConditions/', params)
      .then(() => {
        alert('更新しました。')
        this.jumpToListpage()
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.isProcessing = false
        this.jumpToListpage()
      })
  }

  // 一覧画面へ遷移
  private jumpToListpage() {
    const jumpto = `/teacher/setting/student/${this.searchQuery}`
    window.location.href = jumpto
  }

  get setUnit(): string {
    let unit = ''
    switch (this.exitConditionValue) {
      case '問題数':
        unit = '問'
        break
      case '時間':
        unit = '分'
        break
      case '理解度':
        unit = '%'
        break
    }
    return unit
  }
}
